import * as React from 'react'

import { Box, Container, Grid } from '@mui/material'
import { PageProps, graphql } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'

import {
  BlogPostCard,
  Section,
  SectionWrapper,
  SeoHeaders,
  Subtitle,
  Title,
} from 'shared/components'

import Layout from '../../layouts/articles'

import type { BlogAuthor, BlogImageData } from 'shared/components'
import '@fontsource/covered-by-your-grace/400.css'

type BlogPostData = {
  id: string
  slug: string
  category: string
  createdAt: string
  title: string
  heroImage: BlogImageData
  authors: BlogAuthor[]
}

type QueryProps = {
  allContentfulBlogPost: {
    edges: {
      node: BlogPostData
    }[]
  }
}

export const query = graphql`
  query BlogIndexQuery {
    allContentfulBlogPost(sort: { fields: createdAt, order: DESC }) {
      edges {
        node {
          id
          slug
          category
          createdAt
          title
          heroImage {
            gatsbyImageData(formats: WEBP, width: 600, quality:90)
          }
          authors {
            name
            avatar {
              gatsbyImageData(formats: WEBP, width: 40, quality:90)
            }
            bio {
              raw
            }
          }
        }
      }
    }
  }
`

const BlogIndex = ({ data: { allContentfulBlogPost } }: PageProps<QueryProps>) => (
  <Layout>
    <SeoHeaders
      title='Articles'
      description={(
        'Descubre los blogs de Guita. Hablamos de fintech,'
        + 'consejos sobre AML y todo lo que debes saber del mundo financiero digital.'
        + '¡Aprende con nosotros!'
      )}
      ogImage='/images/articles/og-image.jpg'
    />
    <Container maxWidth='lg'>
      <SectionWrapper>
        <Section>
          <StaticImage
            src='../../images/articles/hero-eth.png'
            alt='Cool Ethereum Logo Drawing'
            height={120}
            objectFit='contain'
            placeholder='blurred'
            quality={90}
          />
          <Title
            mainTitle
            color='text.secondary'
            fontWeight={300}
            align='center'
            sx={{
              fontFamily: '"Covered By Your Grace", serif',
              fontStyle: 'italic',
            }}
          >
            Guita&nbsp;Articles
          </Title>
        </Section>
        <Section>
          <Subtitle
            mainTitle
            textAlign='center'
            gutterBottom
          >
            Artículos Recientes
          </Subtitle>
          <Box sx={{ flexGrow: 1 }}>
            <Grid
              container
              rowSpacing={9}
              columnSpacing={3}
              alignItems='stretch'
            >
              {allContentfulBlogPost.edges.map(({ node }, index) => (
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={4}
                  key={index}
                >
                  <BlogPostCard
                    urlPrefix='/articulos/posts'
                    slug={node.slug}
                    image={node.heroImage}
                    title={node.title}
                    category={node.category}
                    createdAt={node.createdAt}
                    authors={node.authors}
                  />
                </Grid>
              ))}
            </Grid>
          </Box>
        </Section>
      </SectionWrapper>
    </Container>
  </Layout>
)

export default BlogIndex
