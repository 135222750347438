import * as React from 'react'

import { Button } from '@mui/material'

import NavBar from '../components/nav_bar'

type LayoutProps = {
  showProgress?: boolean
  progressValue?: number
  children: React.ReactNode
}

const NavBarButtons = () => (
  <React.Fragment>
    <Button
      href='/articulos/'
      variant='text'
      size='large'
      sx={{ display: { xs: 'none', sm: 'block' } }}
    >
      Artículos
    </Button>
    <Button
      id='cta-header'
      href='/contacto'
      variant='contained'
      sx={{
        bgcolor: 'primary.light',
        color: 'common.black',
      }}
    >
      Empezar
    </Button>
  </React.Fragment>
)

export const Layout = ({
  showProgress,
  progressValue,
  children,
}: LayoutProps) => {
  return (
    <React.Fragment>
      <NavBar
        maxWidth='lg'
        showProgress={showProgress}
        progressValue={progressValue}
      >
        <NavBarButtons />
      </NavBar>
      {children}
    </React.Fragment>
  )
}

export default Layout
